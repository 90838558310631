@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-variant: small-caps;
}

body {
  max-width: 100vw;
  max-height: 100vh;
  background: rgb(34, 39, 55);
  color: white;
}

.ct-app-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 30px;
  border-radius: 2px;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

.ct-small-font {
  font-size: 8px !important;
  margin-top: 5px;
}

.ct-medium-font {
  font-size: 11px !important;
}

.ct-center-font {
  text-align: center !important;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant: small-caps;
}

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-10 {
  margin-bottom: 10px;
}

.ct-mb-20 {
  margin-bottom: 20px !important;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

.ct-pt-20 {
  padding-top: 20px !important;
}

.ct-pb-10 {
  padding-bottom: 10px;
}

.ct-mt-5 {
  margin-top: 5px;
}

.ct-mt-10 {
  margin-top: 10px !important;
}

.ct-mt-20 {
  margin-top: 20px;
}

.ct-mr-5 {
  margin-right: 5px !important;
}

.ct-mr-10 {
  margin-right: 10px !important;
}

a {
  font-weight: bold;
  color: black;
}

.ct-title {
  display: flex;
  flex-direction: column;
}

.ct-title > span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

.ct-cities-tracking > .ct-title .ct-actions {
  font-size: 14px;
  display: flex;
}

.ct-cities-tracking > .ct-title .ct-actions span {
  display: flex;
  font-weight: 400;
  margin-right: 10px;
}

@media screen and (min-width: 800px) {
  .ct-cities-tracking > .ct-title {
    display: flex;
    flex-direction: row;
  }
}

input,
input:hover,
input:active,
input:focus {
  outline: none;
}

button {
  background-color: black;
  color: white;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  height: 25px;
}

button:disabled {
  background: rgb(211, 89, 89);
}

.ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  text-align: right;
  display: block;
  font-weight: 300;
  color: white;
}

.ct-page-title h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.ct-page-title h3 > img {
  width: 50px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Spinners                               */
/* -------------------------------------------------------------------------- */

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.ct-title {
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  padding: 15px;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 90%;
  margin: 20px auto;
}

.ct-title > h3 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.ct-title > h3 > img {
  height: 70px;
}

.ct-title > h3 > div {
  display: flex;
  flex-direction: column;
}

.ct-title > h3 > div > h4 {
  font-size: 14px;
}

.ct-title > h3 > div > small {
  font-weight: lighter;
  font-size: 12px;
}

/* Roadmap */

.ct-roadmap,
.ct-liquidity {
  background: rgb(26, 28, 35);
  color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ct-roadmap > .ct-header,
.ct-liquidity > .ct-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.ct-roadmap > .ct-header > .ct-info > small,
.ct-liquidity > .ct-header > .ct-info > small {
  font-size: 12px;
  font-weight: lighter;
}

.ct-roadmap > .ct-header > span {
  cursor: pointer;
}

.ct-roadmap > div.ct-builds-container {
  display: flex;
  flex-direction: column;
  color: black;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
}

.ct-roadmap > div.ct-builds-container > h4 {
  margin-bottom: 10px;
  font-size: 14px;
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 7px;
}

.ct-roadmap > div > div.ct-scroll-container {
  overflow-x: auto;
  width: 100%;
}

.ct-roadmap > div > div > div.ct-builds {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.ct-roadmap > div > div > div.ct-builds > div {
  background: white;
  margin: 5px;
  height: 100px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.ct-roadmap > div > div > div.ct-builds > div > h4 {
  font-size: 12px;
  margin-bottom: 7px;
}

.ct-roadmap > div > div > div.ct-builds > div > p {
  font-size: 11px;
  line-height: 11px;
}

.ct-roadmap > div > div.ct-progress-bar {
  background: rgb(16, 16, 16);
  border-radius: 3px;
  margin-top: 7px;
  text-align: center;
  color: white;
  position: relative;
  height: 20px;
}

.ct-roadmap > div > div.ct-progress-bar > div {
  background: rgb(35, 95, 35);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.ct-roadmap > div > div.ct-progress-bar > small {
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 5px);
  font-size: 10px;
  font-weight: lighter;
}

.ct-token-info {
  background: rgb(26, 28, 35);
  padding: 20px;
  width: 90%;
  border-radius: 5px;
  position: relative;
}

.ct-token-info .ct-chart-container {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.ct-token-info > .ct-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.ct-token-info > .ct-header > small {
  margin-left: 10px;
}

.ct-token-info > div.ct-info-container {
  margin-top: 20px;
  color: white;
  position: relative;
  z-index: 1;
}

.ct-token-info > div.ct-info-container > h5 {
  margin-bottom: 7px;
}

.ct-token-info > div.ct-info-container > p {
  font-size: 12px;
}

.ct-liquidity > .ct-chart-container {
  width: 100%;
  height: 250px;
}

.custom-tooltip {
  background: white;
  padding: 10px;
  border-radius: 0;
  color: black;
}

.custom-tooltip p {
  font-size: 12px;
}

.custom-tooltip p.label {
  font-size: 16px;
}

.ct-cities-statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 90%;
  justify-content: center;
}

.ct-cities-statistics > span {
  background: white;
  padding: 15px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
}

.ct-cities-statistics > span > p {
  font-size: 10px;
  font-weight: bold;
  margin-top: 7px;
}

.ct-cities-statistics > span > h4 {
  font-size: 12px;
  margin-bottom: 7px;
}

.ct-cities-statistics > span > small {
  font-size: 12px;
}

.ct-relations-show {
  width: 90%;
}

.ct-relations-show > div {
  margin-bottom: 10px;
}

.ct-relations-show > div.ct-relations-title {
  display: flex;
  justify-content: space-between;
}

.ct-relations-show > div.ct-relations-title .ct-actions > span {
  margin: 7px;
  cursor: pointer;
}

.ct-relations-show > div.ct-treemap-chart {
  width: 100%;
  height: 400px;
}

.ct-relations-show > div.ct-relations-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-relations-show > div.ct-relations-container > div {
  background: white;
  margin: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
}

.ct-relations-show > div.ct-relations-container > div > h4,
.ct-relations-show > div.ct-relations-container > div > small {
  font-size: 12px;
}
